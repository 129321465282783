<template>
  <UnitsFrame>
    <MazoForm
      v-if="flashcardsByMazo.results"
      :current-mazo="flashcardsByMazo"
      @setNewsFlashcards="setNewsFlashcards"
    />
  </UnitsFrame>
</template>

<script>
import UnitsFrame from '../../../components/units/UnitsFrame.vue'
import MazoForm from '../../../components/mazos/MazoForm.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { UnitsFrame, MazoForm },
  data: () => ({
    newsFlashcards: []
  }),
  computed: {
    ...mapState('myflashcards', ['flashcardsByMazo'])
  },
  methods: {
    ...mapActions('myflashcards', ['fetchFlashcardsByMazo']),
    setNewsFlashcards (payload) {
      this.newsFlashcards = payload
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (this.newsFlashcards.length) {
      const confirm = await this.$root.$confirm({
        title: '¿Salir de la página?',
        message: 'Aún no actualizaste el mazo. ¿Seguro que quieres salir sin hacerlo?',
        acceptText: 'Si',
        cancelText: 'No'
      })
      if (confirm) {
        next()
      }
    } else {
      next()
    }
  },
  async created () {
    await this.fetchFlashcardsByMazo({ id: this.$route.params.id, search: '' })
  }
}
</script>
